const MenuItems = [
    {
        title: 'Parent Documents',
        path: '/parent',
        cName: 'dropdown-link'
    },
    {
        title: 'EYFS',
        path: '/eyfs',
        cName: 'dropdown-link'
    },
    {
        title: 'Policies and Procedures',
        path: '/policies',
        cName: 'dropdown-link'
    },

]

export default MenuItems;